import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
  },
  {
    path: "/verification-center",
    name: "VerificationCenter",
    component: () =>
      import(
        /* webpackChunkName: "VerificationCenter" */ "@/views/VerificationCenter"
      ),
  },
  {
    path: "/hr-automation",
    name: "KedoAutomation",
    component: () =>
      import(/* webpackChunkName: "KedoAutomation" */ "@/views/KedoAutomation"),
  },
  {
    path: "/corporate-portal",
    name: "CorporatePortal",
    component: () =>
      import(
        /* webpackChunkName: "CorporatePortal" */ "@/views/CorporatePortal"
      ),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "About" */ "@/views/About"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    beforeEnter(to, from, next) {
      window.location.href = "https://ediweb.com/ru-ru/policies/privacy";
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import(/* webpackChunkName: "Error" */ "@/views/Error"),
  },
  {
    path: "/success",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "Success" */ "@/views/Success"),
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/views/NotFound"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to, from, next) => {
  if (window.document.body.classList.contains("nav-opened")) {
    window.document.body.classList.remove("nav-opened");
  }
});

export default router;

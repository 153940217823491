import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import Meta from "vue-meta";
import VScroll from "@/plugins/v-onscroll";
import VModal from "vue-js-modal";
import {
  Validator,
  install as VeeValidate,
} from "vee-validate/dist/vee-validate.minimal.esm";
import { required, email } from "vee-validate/dist/rules.esm";

Vue.config.productionTip = false;

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VScroll);

Vue.use(VModal, {
  resizable: true,
  componentName: "v-modal",
});

const dictionary = {
  ru: {
    messages: {
      email: "Введите корректный email",
      required: "Это обязательное поле",
    },
  },
};

let veeValidateConfig = {
  locale: "ru",
  dictionary,
};

Validator.extend("required", required);
Validator.extend("email", email);

Vue.use(VeeValidate, veeValidateConfig);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="app">
    <div class="main">
      <router-view />
    </div>
    <div class="footer">
      <div class="footer-container container">
        <div class="footer-copyright">
          <p>&copy; WorkCat, 2022</p>
        </div>
        <div class="footer-nav">
          <router-link :to="{ name: 'VerificationCenter' }"
            >Удостоверяющий центр</router-link
          >
          <router-link :to="{ name: 'KedoAutomation' }"
            >Автоматизация КЭДО</router-link
          >
          <router-link :to="{ name: 'CorporatePortal' }"
            >Корпоративный портал</router-link
          >
          <router-link :to="{ name: 'About' }">О нас</router-link>
        </div>
        <p class="footer-policy">
          <router-link :to="{ name: 'PrivacyPolicy' }"
            >Политика конфиденциальности</router-link
          >
        </p>
      </div>
      <v-copyright></v-copyright>
    </div>
  </div>
</template>

<script>
import config from "./config";

export default {
  name: "app",
  components: {
    "v-copyright": () => import("@/components/v-copyright.vue"),
  },
  metaInfo() {
    return {
      titleTemplate: "%s",
      meta: [
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image:url",
          content: config.og.image.url,
        },
        {
          property: "og:image:type",
          content: config.og.image.type,
        },
        {
          property: "og:image:width",
          content: config.og.image.width,
        },
        {
          property: "og:image:height",
          content: config.og.image.height,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: this.getURI(),
        },
        {
          rel: "icon",
          href: "/favicon.ico",
          sizes: "any",
        },
        {
          rel: "icon",
          href: "/favicon.svg",
          type: "image/svg+xml",
        },
        {
          rel: "apple-touch-icon",
          href: "/apple-touch-icon.png",
          sizes: "180x180",
        },
        {
          rel: "manifest",
          href: "/manifest.webmanifest",
        },
        {
          rel: "mask-icon",
          href: "/safari-pinned-tab.svg",
          color: "#5170dd",
        },
        {
          name: "msapplication-TileColor",
          content: "#5170dd",
        },
        {
          name: "theme-color",
          content: "#ffffff",
        },
      ],
    };
  },
  methods: {
    getURI() {
      return `${config.baseURL}${this.$route.path}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>

module.exports = {
  baseURL: "https://workcat.ru",
  og: {
    type: "website",
    image: {
      url: "/",
      width: "512",
      height: "512",
      type: "image/png",
    },
  },
  pages: {
    // eslint-disable-next-line
    content: [
      "/",
      "/verification-center",
      "/hr-automation",
      "/corporate-portal",
      "/about",
    ],
    // eslint-disable-next-line
    tech: [
      "/privacy-policy",
      "/success",
      "/error",
      "/404",
    ],
  },
};
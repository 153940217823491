export default {
  onChange(entries) {
    entries.map((entry) => {
      const el = entry.target;
      const style = el.style;
      const bindings = el.$onscroll.bindings;
      const delay = parseInt(bindings?.value?.delay || 0, 10);
      if (el.$onscroll.triggered == false) {
        if (entry.isIntersecting) {
          el.$onscroll.triggered = true;
          el.classList.add('onscroll--visible');
          setTimeout(() => {
            el.classList.remove('onscroll', 'onscroll--visible');
          }, entry.boundingClientRect.top < 0 ? 0 : delay + 1000);
        } else {
          el.classList.add('onscroll');
          if (delay) {
            style.setProperty('transition-delay', `${delay}ms, ${delay}ms`);
          }
        }
      }
    });
  },
  registerStylesheet() {
    const css = window.document.styleSheets[0];
    css.insertRule(`
        @media only screen and (min-width: 860px) {
          .onscroll {
            overflow: hidden;
            transition:
              opacity 0.4s ease-out,
              transform 0.4s ease-out;
          }
          .onscroll > * {
            transform: translateY(50vh);
            opacity: 0;
            transition: inherit;
          }
        }
      `,
      css.cssRules.length,
    );
    css.insertRule(`
        @media only screen {
          .onscroll--visible > * {
            transform: translateY(0%);
            opacity: 1;
          }
        }
      `,
      css.cssRules.length,
    );
    return this;
  },
  register(el, options) {
    const observer = new IntersectionObserver(this.onChange, {
      threshold: 0.25,
    });
    observer.observe(el);
    return observer;
  },
  registered(el) {
    return el.$onscroll instanceof IntersectionObserver;
  },
  install(Vue, options) {
    this.registerStylesheet(options);
    Vue.directive('onscroll', (el, bindings) => {
      if (!this.registered(el)) {
        el.$onscroll = {
          bindings,
          observer: this.register(el, bindings.value),
          triggered: false,
        };
      }
    });
  }
};